import PropTypes from 'prop-types';
import React from 'react';

import './_burger.scss';

const Burger = (props) => {
	return (
		<div className="burger js-burger" onClick={props.onClick}>
			<div className="burger__brick"/>
			<div className="burger__brick burger__brick--middle"/>
			<div className="burger__brick"/>
		</div>
	);
};

Burger.propTypes = {};

Burger.defaultProps = {};

export default Burger;
