import PropTypes from 'prop-types';
import React from 'react';

import Header from '../Header/header';
import './_content.scss';

const Content = ({ children }) => {
	if (typeof window !== "undefined") {
		const SmoothScroll = require('smooth-scroll');
		new SmoothScroll('a[href*="#"]', {
			speed: 600
		});
	}
	return (
	<>
		<Header />
		<main className="content">
			{children}
		</main>
	</>
)};

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
