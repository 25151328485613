import PropTypes from 'prop-types';
import React from 'react';

import './_section.scss';

const SectionHeader = ({ image, headline, isFirst }) => {
	const Tag = isFirst ? 'h1' : 'h2';
	if (image) {
		const style = { backgroundImage: `url("${image}")` };
		return (
			<div key={headline} className="m-section__header" style={style}>
				<Tag>{headline}</Tag>
			</div>);
	} else {
		return (
			<div key={headline} className="m-section__header m-section--smallHeader">
				<Tag>{headline}</Tag>
			</div>);
	}
};

const Content = ({ content }) => {
	switch (content.type) {
		case 'paragraph':
			return (<p className="m-section__text"
								 dangerouslySetInnerHTML={{ __html: content.text }}/>);
		case 'headline':
			return (<h1>{content.text}</h1>);
		case 'subhead':
			return (<h2 className="m-section__subheading">{content.text}</h2>);
		case 'textimage':
			console.log('content', content);
			return (
				<p className="m-section__text_img">
					<img src={content.image} title={content.alt} alt={content.alt}/>
					<span dangerouslySetInnerHTML={{ __html: content.text }}/>
				</p>
			);
		case 'socialmedia':
			return (
				<div className="icon-wrapper">
					<a href="mailto:info@lektorat-kasper.de" target="_blank" rel="noopener noreferrer" title={'E-Mail'}
						 className="icon socicon-mail"><span>Mail</span></a>
					<a href="https://www.facebook.com/pia.kasper.754" title="Facebook" target="_blank" rel="noopener noreferrer"
						 className="icon socicon-facebook"><span>Facebook</span></a>
					<a href="https://www.xing.com/profile/Pia_Kasper3" title="Xing" target="_blank" rel="noopener noreferrer"
						 className="icon socicon-xing"><span>Xing</span></a>
					<a href="https://www.linkedin.com/in/pia-kasper-30b714160/" title="LinkedIn" target="_blank"
						 rel="noopener noreferrer"
						 className="icon socicon-linkedin"><span>Linkedin</span></a>
					<a href="https://www.instagram.com/lektorat_kasper/" title="Instagram" target="_blank"
						 rel="noopener noreferrer"
						 className="icon socicon-instagram"><span>Instagram</span></a>
					<div className="vfll">
						<a href='https://www.vfll.de' title='VFLL' target='_blank' rel='noopener noreferrer'
							 className={'vfll__red'}><img src={'images/VFLL_Textmarke_rote_Flaeche.jpg'} alt="VFLL" title="VFLL"/></a>
						<a href='https://www.vfll.de' title='VFLL' target='_blank' rel='noopener noreferrer'
							 className={'vfll__white'}><img src={'images/VFLL_Textmarke_weisse_Flaeche.jpg'} alt="VFLL" title="VFLL"/></a>
					</div>
				</div>
			);
		default:
			return null;
	}
};

const Section = (props, index) => {
	const isFirst = index === 0;
	return (
		<section key={props.id} id={props.id} className={`m-section m-section--${props.id}`}>
			<SectionHeader image={props.image} headline={props.headline} isFirst={isFirst}/>
			<div className="m-section__content">
				{props.contents.map((content, index) => <Content key={`${content.type}-${index}`} content={content}/>)}
			</div>
		</section>
	);
};

Section.propTypes = {
	id: PropTypes.string.isRequired
};

Section.defaultProps = {};

export default Section;
