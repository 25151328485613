import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SEO from '../components/seo';
import Content from '../components/Content/content';
import Section from '../components/Section/section';

import '../assets/styles/main.scss';

const IndexPage = () => {
	return (
		<Content>
			<SEO title="Lektorat Kasper - freie Lektorin aus Hamburg"/>
			<Sections/>
		</Content>
	);
};

export default IndexPage;

const Sections = () => (
	<StaticQuery
		query={
			graphql`
				query IndexSectionsQuery {
					allFile(filter: {
						name: { eq : "index" }
					}) {
						edges {
							node {
								childrenIndexJson {
									id
									headline
									contents {
										type
										text
										image
										alt
									}
									image
								}
							}
						}
					}
				}
	`}
		render={data => (data.allFile.edges[0].node.childrenIndexJson.map((section, index) => Section(section, index)))}
	/>
);