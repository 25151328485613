import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'gatsby';

import Nav from '../Nav/nav';
import Burger from '../Burger/burger';

import './_header.scss';

const Header = () => {
	const [isOpen, setOpen] = useState(false);
	return (
		<header className={'header' + (isOpen ? ' header--open' : '')}>
			<Link to="#intro" className="header__logo link" title={'Einleitung'}>
				<img className="header__logo-img" src="/images/Logo_Kasper.svg" alt="Logo Lektorat Kasper" title="Logo Lektorat Kasper"/>
				<img className="header__logo-img--white" src="/images/Logo_Kasper_white.svg" alt="Logo Lektorat Kasper" title="Logo Lektorat Kasper"/>
			</Link>

			<Nav toggleOpen={() => setOpen(!isOpen)}/>

			<Burger onClick={() => setOpen(!isOpen)}/>
		</header>
	);
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
