import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

import './_nav.scss';

const Nav = (props) => {
	return (
		<nav className="navi">
			<ul className="navi__links">
				<li className="navi__link">
					<Link to="#leistungen" onClick={props.toggleOpen} title={'Leistungen'}>Leistungen</Link>
				</li>
				<li className="navi__sep">|</li>
				<li className="navi__link">
					<Link to="#preise" onClick={props.toggleOpen} title={'Preise'}>Preise</Link>
				</li>
				<li className="navi__sep">|</li>
				<li className="navi__link">
					<Link to="#about" onClick={props.toggleOpen} title={'Über mich'}>Über mich</Link>
				</li>
			</ul>

			<ul className="subnavi">
				<li className="subnavi__link">
					<Link to="/impressum" onClick={props.toggleOpen} title="Impressum">Impressum</Link>
				</li>
				<li className="subnavi__link">
					<Link to="/datenschutz" onClick={props.toggleOpen} title="Datenschutzerklärung">Datenschutzerklärung</Link>
				</li>
			</ul>
		</nav>)};

Nav.propTypes = {
};

Nav.defaultProps = {
};

export default Nav;
