/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitter
            image
            url
            keywords
          }
        }
      }
    `
	);

	const metaDescription = description || site.siteMetadata.description;
	const schema = {
		'@context': 'http://schema.org',
		'@type': 'WebSite',
		'url': 'https://lektorat-kasper.de',
		'logo': 'https://lektorat-kasper.de/images/Pia.jpg',
		'contactPoint': [
			{
				'@type': 'ContactPoint',
				'url': 'https://lektorat-kasper.de/impressum',
				'contactType': 'sales'
			}]
	};
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: `author`,
					content: site.siteMetadata.author,
				},
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:image`,
					content: site.siteMetadata.image,
				},
				{
					property: `og:locale`,
					content: lang,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:url`,
					content: site.siteMetadata.url,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.twitter,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `twitter:image`,
					content: site.siteMetadata.image,
				},
				{
					name: `twitter:url`,
					content: site.siteMetadata.url,
				},
			].concat(
				site.siteMetadata.keywords.length > 0
					? {
						name: `keywords`,
						content: site.siteMetadata.keywords.join(`, `),
					}
					: []
			).concat(meta)}
		>
			<script type="application/ld+json">
				{JSON.stringify(schema)}
			</script>
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `de_De`,
	meta: [],
	keywords: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
};

export default SEO;
